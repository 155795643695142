import React, { useState } from 'react'
import { FilePond } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import axios from "axios";

// const baseUrl = "http://localhost:9001" // Use this when running locally with "npm start"
const baseUrl = window.location.protocol + "//" + window.location.host;

function App() {
  const [files, setFiles] = useState([])
  const [filelist, setFilelist] = useState([])
  const [downloadable, setDownloadable] = useState(false)
  const [downloadKey, setDownloadKey] = useState('')

  const afterUpload = (err, file) => {
    loadFilelist();
  }

  const loadFilelist = async () => {
    let filelist = await axios.get(`${baseUrl}/filelist/${downloadKey}`);
    setFilelist(filelist.data)
  }

  return (
    <div className="container bg-dark">
      <div className="App" style={{ color: 'white' }}>
        <h3 className="text-center text-light">File uploader</h3>

        <div className="row mb-3">
          <div className="col-4">
            <input type="password" id="form12" className="form-control" placeholder="download key" value={downloadKey} onChange={(e) => { setDownloadKey(e.target.value) }} />
          </div>
          <div className="col-4 pt-2">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value={downloadable} id="flexCheckChecked" onChange={() => { setDownloadable(!downloadable) }} />
              <label className="form-check-label" htmlFor="flexCheckChecked">Downloadable with this key</label>
            </div>
          </div>
        </div>

        <FilePond
          files={files}
          onupdatefiles={setFiles}
          allowMultiple={true}
          maxFiles={100}
          server={`${baseUrl}/upload/${downloadable ? downloadKey : ''}`}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          onprocessfile={afterUpload}
        />
        <button type="button" onClick={loadFilelist} className="btn btn-primary btn-sm ml-0">Load file list</button>
        <div className="card p-2 text-dark">
          {filelist.length === 0 ? <div style={{ opacity: .5 }}>(empty list)</div> : null}
          {filelist.map(file => {
            return (
              <div key={file.id}>
                <a href={`${baseUrl}/download/${file.id}`}>{file.filename}</a>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
